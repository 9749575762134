import React, {useState} from 'react'
import OrderCardMenuExtra from './OrderCard.menu-extra'
import {twMerge} from 'tailwind-merge'
import OrderCardMoreButton from './OrderCard.more-button'
import OrderCardMenuModal, {SelectedMenu} from './OrderCard.menu-modal'
import {KDSOrderItemModel} from 'src/app/services/order/Order.model'
import {useUpdateQtyDone} from 'src/app/services/order/Order.update-qty-done.provider'
import OrderCardMenuPackage from './OrderCard.menu-package'
import {useHeaderToast} from 'src/app/context/ToastContext'
import {useOnline} from 'src/app/hooks/online-hook'

type OrderCardMenuProps = {
  posId?: number
  data?: KDSOrderItemModel[]
  className?: string
}

const DATA_TO_SHOW = 4

const OrderCardMenuIcon = () => {
  return (
    <svg
      width='21'
      height='20'
      className='mt-2'
      viewBox='0 0 21 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.125 11L12.125 15M12.125 15L16.125 11M12.125 15V5C12.125 4.44771 11.6773 4 11.125 4H5.125'
        stroke='#667085'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

const OrderCardMenu = ({data, className, posId}: OrderCardMenuProps) => {
  const [itemsToShow, setItemsToShow] = useState(DATA_TO_SHOW)
  const [orderModal, setOrderModal] = useState<boolean>(false)
  const [selected, setSelected] = useState<KDSOrderItemModel>()
  const updateQtyDone = useUpdateQtyDone()
  const {addPageToasts} = useHeaderToast()
  const {isOnline} = useOnline()

  const handleDone = async (data: SelectedMenu, isDoneAll: boolean) => {
    if (isOnline) {
      try {
        await updateQtyDone.mutateAsync({
          type: 'POST',
          data,
        })
        if(isDoneAll){
          addPageToasts({
            scheme: 'success',
            text: `All the ${data.menu_name} finished`,
          })
        } else {
          addPageToasts({
            scheme: 'success',
            text: `${data.kds_qty_done} ${data.menu_name} finished`,
          })
        }
      } catch (error) {
        addPageToasts({
          scheme: 'danger',
          text: `Network failed`,
        })
      } finally {
        setOrderModal(false)
      }
    } else {
      setOrderModal(false)
      addPageToasts({
        scheme: 'danger',
        text: `Failed to complete the order. Please check your connection and try again.`,
      })
    }
  }

  const renderListItem = (item: KDSOrderItemModel, index: number) => {
    const restQty = item.qty - item.qty_done

    if (item.qty === item.qty_done) return null

    const showExtraIcon = item.menu_extras.length > 0 || item.menu_packages.length > 0

    return (
      <div
        key={index}
        className='border-b px-4 py-2.5 cursor-pointer duration-300 hover:bg-primary-50'
        onClick={() => {
          setSelected(item)
          setOrderModal(true)
        }}
      >
        <div className='flex'>
          <div className='w-12'>
            <span className='text-sm text-neutral-500 font-medium'>{restQty} x</span>
          </div>
          <div className='flex-1'>
            <p className='text-sm text-neutral-800 font-medium'>{item?.menu_name}</p>
          </div>
          {showExtraIcon && <OrderCardMenuIcon />}
        </div>
        {item.note && (
          <div className='mt-1 mb-2 flex space-x-1 text-xs text-primary-900'>
            <span>Notes:</span>
            <p>{item.note}</p>
          </div>
        )}
        <OrderCardMenuPackage data={item.menu_packages} />
        <OrderCardMenuExtra data={item.menu_extras} />
      </div>
    )
  }

  return (
    <>
      <div className={twMerge('pt-6', className)}>
        {data?.slice(0, itemsToShow)?.map(renderListItem)}
        {data && data.filter((item) => item.qty_done !== item.qty).length > DATA_TO_SHOW && (
          <OrderCardMoreButton
            onClickSeeLess={() => setItemsToShow(DATA_TO_SHOW)}
            onClickSeeMore={() => setItemsToShow(data.length)}
          />
        )}
      </div>
      <OrderCardMenuModal
        handleDone={(e) => handleDone(e, false)}
        handleDoneAll={(e) => handleDone(e, true)}
        handleClose={() => {
          setOrderModal(false)
          setSelected(undefined)
        }}
        show={orderModal}
        data={selected}
        loading={false}
        posId={posId}
      />
    </>
  )
}

export default OrderCardMenu
