import React from 'react'
import {Card, FormBuilder, GButton} from 'src/app/components/Libs'
import {useSettingsForm} from './Settings.form-hook'

export type SettingsFormProps = {
  handleClose?: () => void
}

const SettingsForm = (props: SettingsFormProps) => {
  const state = useSettingsForm(props)

  return (
    <form className='w-full flex-1' onSubmit={state.formik.handleSubmit} noValidate>
      <Card.Body className='px-6'>
        <FormBuilder formik={state.formik} generated={state.generatedForm} />
        <div className='flex  gap-x-5 w-full mb-6 mt-auto'>
          <GButton onClick={() => {
            state.action.handleCancel()
            }} type='button' variant='OUTLINED' className='w-full'>
            Cancel
          </GButton>
          <GButton type='submit' className='w-full bg-warning-500 text-white border-warning-500'>
            Save
          </GButton>
        </div>
        <div className='w-full'>
          <GButton type='button' className='w-full' onClick={state.action.handleCheckForUpdate}>
            Check for Update
          </GButton>
        </div>
      </Card.Body>
    </form>
  )
}

export default SettingsForm
