import {FC, MouseEventHandler, useEffect, useState} from 'react'
import {FormCheckbox, GButton, GModal} from 'src/app/components/Libs'
import OrderCardSwiper from './OrderCard.swiper'
import {twMerge} from 'tailwind-merge'
import {KDSOrderItemModel} from 'src/app/services/order/Order.model'

export type SelectedMenu = {
  pos_item_id?: number
  pos_id?: number
  is_print_checker?: boolean
  kds_qty_done?: number
  menu_name?: string
}

export type SelectedOrder = {
  pos_id?: number
  is_print_checker?: boolean
}

export type OrderCardMenuModalProps = {
  show: boolean
  handleClose: MouseEventHandler
  handleDone: (data: SelectedMenu, isDoneAll: boolean) => void
  handleDoneAll: (data: SelectedMenu, isDoneAll: boolean) => void
  posId?: number
  data?: KDSOrderItemModel
  loading: boolean
}

const OrderCardMenuModal: FC<OrderCardMenuModalProps> = ({
  show,
  handleClose,
  handleDone,
  handleDoneAll,
  posId,
  data,
  loading,
}) => {
  const [tmpQty, setTmpQty] = useState<any>(0)
  const [isPrintChecker, setIsPrintChecker] = useState(false)

  useEffect(() => {
    setTmpQty(0)
  }, [data])

  const qty = data?.qty ?? 0  
  const kdsQtyDone = data?.qty_done ?? 0  
  const defaultQty = (qty - kdsQtyDone) || 1
  const defaultOption = Array.from({length: defaultQty}, (_, i) => i + 1)

  return (
    <GModal show={show} onHide={handleClose} size={'md'}>
      <div className='flex items-center bg-white rounded-xl w-full rounded-tr justify-between p-6 font-medium border-b border-neutral-30'>
        <div className={twMerge(['flex flex-col items-center text-xl font-semibold text-black'])}>
          <div className='w-full'>{data?.menu_name}</div>
        </div>
        <FormCheckbox
          checkedValue={true}
          activeValue={isPrintChecker}
          inactiveValue={false}
          changedValue={(e: any) => setIsPrintChecker(e.target.checked)}
          variant='switch'
          sizebox={'large'}
          placeholder='Print Checker'
        />
      </div>

      <div className='px-6 py-5 min-h-[100px]  w-full'>
        <div className='flex flex-col justify-center items-center'>
          <div className='text-center mb-5'>
            <h5 className='text-neutral-600 font-medium text-lg mb-1'>Total Finished</h5>
            <span className='text-neutral-800 font-semibold text-[24px] '>{tmpQty}</span>
          </div>
          <OrderCardSwiper
            data={defaultOption}
            selected={tmpQty}
            onChange={(value) => setTmpQty(value)}
          />
        </div>
      </div>
      <div className='flex gap-x-3 justify-between items-center w-full px-6 py-5 border-t border-neutral-30'>
        <GButton
          onClick={handleClose}
          type='button'
          variant='OUTLINED'
          className='min-w-[120px] text-sm font-medium border border-primary-500 text-primary-500'
          disabled={loading}
        >
          Cancel
        </GButton>

        <div className='flex space-x-6'>
          <GButton
            onClick={() =>
              handleDone({
                pos_item_id: data?.pos_item_id,
                pos_id: posId,
                kds_qty_done: tmpQty,
                is_print_checker: isPrintChecker,
                menu_name: data?.menu_name,
              }, false)
            }
            className='w-[120px] text-sm font-medium bg-warning-500 border-warning-500 text-white disabled:bg-warning-500/50 disabled:text-white'
            type='button'
            loading={loading}
            disabled={tmpQty === 0}
          >
            Done
          </GButton>

          <GButton
            onClick={() =>
              handleDoneAll({
                pos_item_id: data?.pos_item_id,
                pos_id: posId,
                kds_qty_done: defaultOption.length,
                is_print_checker: isPrintChecker,
                menu_name: data?.menu_name,
              }, true)
            }
            className='w-[120px] text-sm font-medium item-end'
            type='button'
            loading={loading}
            disabled={loading}
          >
            Done All
          </GButton>
        </div>
      </div>
    </GModal>
  )
}

export default OrderCardMenuModal
