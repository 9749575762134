import axios from 'axios'
import {apiUrl} from 'src/app/utils/ws-utils'
import {SettingsModel} from './Settings.types'

export const getKDSSettings = () => {
  return axios.get(`${apiUrl}/kds/settings`)
}

export const saveKDSSettings = (payload: SettingsModel) => {
  return axios.put(`${apiUrl}/kds/settings`, payload)
}

export const getKDSHostName = () => {
  return axios.get(`${apiUrl}/kds/hostname`)
}
