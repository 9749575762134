import clsx from 'clsx'
import React, {FC} from 'react'
import GIcon from '../Libs/Icon/GIcon'

const ContentWrapper: FC<{children: React.ReactNode}> = ({children}) => {
  return (
    <div
      className={clsx(
        `flex flex-col flex-[1_1_auto] min-w-0 transition-all duration-300 overflow-auto`
      )}
    >
      <GIcon className='w-6 h-6 mr-3 hidden' icon={'IconCloseRound'} />
      {children}
    </div>
  )
}

export default ContentWrapper
