import {createContext, useCallback, useContext, useEffect, useMemo} from 'react'
import {SettingsModel} from './Settings.types'
import {useDispatch} from 'react-redux'
import SettingsRedux from './Settings.redux'
import {getKDSHostName, getKDSSettings, saveKDSSettings} from './Settings.services'

const SettingsContext = createContext<any>(null)

export const SettingsProvider = ({children}: any) => {
  const dispatch = useDispatch()
  const getKdsSettings = useCallback(() => {
    getKDSSettings()
      .then((res) => {
        dispatch(SettingsRedux.actions.Update(res.data.response_output.detail))
      })
      .catch((err) => {})
      .then(() => {})
  }, [dispatch])

  const saveKdsSettings = useCallback(
    (payload: SettingsModel) => {
      return saveKDSSettings(payload)
        .then((res) => {
          if (res.status === 200) {
            dispatch(SettingsRedux.actions.Update(res.data.response_output.detail))
            return res
          }
          throw new Error(res.data)
        })
        .catch((err) => {
          console.error('Error saving settings:', err)
          return Promise.reject(err)
        })
        .finally(() => {})
    },
    [dispatch]
  )

  const getKdsHostName = useCallback(() => {
    getKDSHostName()
      .then((res) => {
        if (res.status === 200) {
          localStorage.clear()
          localStorage.setItem('WEB_SOCKET_HOSTNAME', res.data.response_output.detail)
        }
      })
      .catch((err) => {
        console.error('Error saving settings:', err)
        return Promise.reject(err)
      })
      .finally(() => {})
  }, [])

  useEffect(() => {
    getKdsSettings()
    getKdsHostName()
  }, [getKdsSettings, getKdsHostName])

  const value = useMemo(
    () => ({
      getKdsSettings,
      saveKdsSettings,
      getKdsHostName,
    }),
    [getKdsSettings, saveKdsSettings, getKdsHostName]
  )

  return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>
}

// Define a custom hook to access the chat messages context
export const useSettingsContext = () => useContext(SettingsContext)
