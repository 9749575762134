import {toAbsoluteUrl} from 'src/app/utils/asset-helpers-utils'
import {GChip} from '../../Libs'
import {useFooter} from './Footer.hook'
import {VisitPurposeModel} from 'src/app/services/visit-purpose/VisitPurpose.model'

const Footer = () => {
  const state = useFooter()

  const renderVisitPurposeItem = (item: VisitPurposeModel) => {
    return (
      <GChip
        key={item.id}
        variant={'gray'}
        endIcon='IconCheckCircle'
        iconClassName='text-success-500'
        size='medium'
        rounded={'full'}
      >
        {item.name}
      </GChip>
    )
  }

  return (
    <footer className='px-8 py-4 min-h-[116px] flex gap-4 bg-white z-20'>
      <div className='bg-neutral-100 py-3 px-5 rounded-[20px] flex-1'>
        <h3 className='text-base text-neutral-800 font-semibold mb-2'>Visit Purpose</h3>
        <div className='flex gap-3 flex-wrap'>{state.visitPurposeSetting && state.visitPurposeSetting?.length > 0 ? state.visitPurposeSetting?.map(renderVisitPurposeItem) : <span>-</span>}</div>
      </div>

      <div className='flex  w-full max-w-[282px] mt-3'>
        <div className='flex gap-3'>
          <img
            className='w-6 h-6'
            src={toAbsoluteUrl('/media/icons/IconCurvedLocation.svg')}
            alt=''
          />
          <div className='text-neutral-500 text-sm'>
            <h3 className='text-neutral-700 font-medium'>{state?.branch.data?.name}</h3>
            <p>{state?.appVersion}</p>
            <p>{state.displayStation}</p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
